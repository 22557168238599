const messages = {
    phone2FA: '2FA Teléfono',
    phoneNotification: 'Notificación Teléfono',
    changePassword: 'Cambia la Contraseña',
    currentPassword: 'Contraseña Actual',
    newPassword: 'Nueva Contraseña',
    confirmPassword: 'Confirmar Contraseña',
    submit: 'Enviar',
    myProfile: 'Mi Perfil',
    passwordChangeSuccessfully: 'La contraseña fue cambiada exitosamente',
    Password: 'Contraseña',
    forgotPassword: 'Has olvidado tu contraseña',
    checkYourEmail: 'Por favor revise su correo electrónico.',
    enterEmailAssociatedYourAccount:
        'Por favor ingrese el correo electrónico asociado con su cuenta.',
    send: 'Enviar',
    enterYourNewPassword: 'Por favor ingrese su nueva contraseña.',
    resetPassword: 'Restablecer Contraseña',
    verifyYourIdentity: 'Verifica Tú Identidad',
    receiveVerificationCode: 'Recibir el código de verificación por',
    enterCodeSentToYour: 'Por favor ingrese el código enviado a su',
    verify: 'Verificar',
    resend: 'Reenviar',
    editPermissions: 'Permisos editados satisfactoriamente',
    workingSince: 'Empleado de la Empresa Desde',
    confirmUnlockAccount: 'Confirmar el desbloqueo de la cuenta de usuario',
    confirmLoginAccount: 'Confirmar ingreso con la cuenta de usuario {field}',
    noticeUnlock:
        'Ésta acción permitita al usuario acceder al sistema, algunos datos podrían perderse.',
    noticeImpersonateLogin:
        'Ésta acción permitiría al usuario acceder al sistema, algunos datos podrían perderse.',
    userAccountUnlokedSuccessfully: 'Cuenta de usuario desbloqueda satisfactoriamente',
    login: 'Login',
    greeting: 'Hola',
    updatePasswordDescription:
        'Requerimos que actualice su contraseña personal, por favor, actualícela ahora.',
    resetPasswordRequest: 'Restablecer Contraseña',
    confirmRequestUpdatePassword: 'Confirmar la solicitud de cambio de contraseña.',
    requestUpdatePasswordSuccessfully:
        'La solicitud de cambio de contraseña fue creada satisfactoriamente.',
    noticeRequestUpdatePassword:
        'Esta acción solicitará al usuario al iniciar sesión que cambie su contraseña.',
    requestPasswordUpdate: 'Cambio de contraseña requerido',
    requestPasswordUpdateDescription:
        'El usuario debe cambiar su contraseña en el próximo inicio de sesión.',
};

export default messages;
