const messages = {
    productAttributes: 'Atributos del Producto',
    textProductsAttributes: 'Los atributos pertenecen a un producto.',
    attributes: 'Atributos',
    exclusiveProducts: 'Exclusiva para Productos',
    addProductAttributes: 'Añadir Atributos',
    notProductAttributes: 'No Atributos del Producto',
    typeAttribute: 'Tipo de Atributo',
    detailsFor: 'Detalles para',
    information: 'Información',
    products: 'Productos',
    addAttributesProductService: 'Adicionar nuevos atributos de productos y servicios',
    editAttributesProductService: 'Editar atributos de productos y servicios',
    picture: 'Imagen',
    addNewProduct: 'Adicionar Nuevo Producto',
    editProduct: 'Editar Producto',
    productAddedSuccessfully: 'Producto adicionado exitosamente',
    productUpdatedSuccessfully: 'Producto editado exitosamente',
    confirmDeleteProduct: 'Confirmar Eliminación del Producto',
    productAvailable: 'El producto está disponible',
    unavailable: 'No Disponible',
    available: 'Disponible',
    attribute: 'Atributo',
    salable: 'Vendible',
    theProductIsSalable: 'Este producto se puede vender',
    productType: 'Tipo de producto',
};

export default messages;
