const messages = {
    'Medical Services': 'Medical Services',
    'Massage appointments': 'Massage Appointments',
    upcommingAppointments: 'Upcoming Appointments',
    patients: 'patients',
    noAppointments: 'No Appointments for this Day',
    hello: 'Hello',
    massageScreenSubheader: "Let's take a look at your patients and appointments for today",
    nonSurgicalScreenSubheader: "Let's take a look at your patients and appointments for today",
    currentPatient: 'Current Patient',
    seeAllSessions: 'See All Sessions',
    sessions: 'Sessions',
    of: 'of',
    nextSessionOn: 'Next Session',
    serviceDuration: 'Service Duration',
    pause: 'Pause',
    begin: 'Begin',
    finishSession: 'Done!',
    evaluate: 'Evaluate',
    lastVisit: 'Last Visit',
    notCheckedInNotification: 'This patient has not checked in',
    notCheckedInNotificationHelpText:
        'You can only start the session after the patient checks in at the frontdesk',
    checkInToStartSession: 'Check in to Start Session',
    seeAllProcedures: 'See All Procedures',
    noPreviousSession: 'No Previous Session',
    allProceduresOfPatient: 'All Procedures of this Patient',
    preOpEvaluation: 'Pre-Op Evaluation',
    chiefComplaints: 'Chief Complaints',
    pmhx: 'P.M.HX',
    heent: 'Heent',
    smooking: 'Smooking',
    cv: 'Cv',
    alcohol: 'Alcohol',
    adb: 'Adb',
    drugs: 'Drugs',
    respiratory: 'Respiratory',
    other: 'Other',
    medication: 'Medication',
    operation: 'Operation',
    allergies: 'Allergies',
    preoptx: 'Pre Op Tx',
    asaStatus: 'ASA Status',
    dateSurgery: 'Date of Surgery',
    remarks: 'Remarks',
    bloodPressure: 'Blood Pressure',
    pulse: 'Pulse',
    gestations: 'Gestations',
    childBirths: 'Child Births',
    aditionalRequirements: 'Aditional Requirements',
    labs: 'Labs',
    xRay: 'XRay',
    eeg: 'ECG',
    ultrasound: 'Ultrasound',
    mammography: 'Mammography',
    cancelSurgeryCauses: 'Surgery appointment cancellation cause',
    cancelSurgeryDescription: 'Select the exact surgery appointment cancellation cause',
    PositiveTest: 'Positive Test',
    MedicalCondition: 'Medical Condition',
    generalPatientDataHelpText: 'general information about the patient',
    pickUpPersonData: ' Person who gonna pick up',
    pickUpPersonDataHelpText: 'Introduce the persons data who gonna pick up the patient',
    completeProceduresDataHelpText: 'Select the procedures that has been completed',
    surgeryTeam: 'Surgery Team',
    completedProcedures: 'Completed Procedures',
    checkOut: 'Check Out',
    errorTestMissing: 'Some test results are missing',
    notPreviousSurgery: 'No previous surgery',
    appointmentsOf: 'Appointments of',
    totalSessionsCompletedToday: 'Total sessions completed today',
    remainingAppointmentsToday: 'remaining appointments today',
    thisMonth: 'This month',
    sessionsCompleted: 'Sessions Completed',
    thisSessionWasCompleted: 'This session was completed',
    emergencyContactName: 'Emergency Contact Name',
    emergencyContactSurname: 'Emergency Contact Surname',
    emergencyContactPhone: 'Emergency Contact Phone',
    serviceStartedInNotification: 'There is a session running',
    serviceStartedInNotificationHelpText:
        "You can't select another session while there is one running",
    startedOn: 'Started on',
    completeMassageSession: 'Finish this massage session?',
    completeMassageSessionHelpText: 'The appointment will be marked as completed',
    completedOn: 'Completed on',
    updateMedicalRecord: 'Update Medical Record',
    createNewMedicalRecord: 'Create New Medical Record',
    cancelSession: 'Cancel current session?',
    cancelSessionHelpText: 'This will reset the service time and start time',
    evaluateAppointment: 'Evaluate appointment',
    uploadEvaluation: 'Upload evaluation',
    thisAppointmentWasCancelled: 'This appointment was cancelled',
    draft: 'Draft',
    classification: 'Classification',
    dispatchAccessories: 'Dispatch accessories',
    dispatchAccessoriesHelpText: 'Optionally you can dispatch the patients accessories from here',
    dispatchNow: 'Dispatch now',
    dispatchSuccessful: 'Dispatch successful',
    ProviderAssistants: 'Provider assistants',
    thisLastWeeks: 'This last {field} Weeks',
    confirmationRecorded:
        'By continuing, you confirm that you have checked to the best of your abilites that the procedures and amounts entered are accurate and up-to-date. Your confirmation will be recorded.',
    doubleCheck: 'Let me double check...',
    doubleCheckThis: 'Double check this.',
    evaluations: 'Evaluations',
};

export default messages;
