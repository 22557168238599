const messages = {
    Type: 'Type',
    createNewAppointment: 'Create New Appointment',
    editAppointment: 'Edit Appointment',
    detailedDailyPayments: 'Detailed Daily Payments',
    applied: 'Applied',
    To: 'to',
    product: 'Product',
    check: 'Check',
    creditCard: 'Credit Card',
    debitCard: 'Debit Card',
    cash: 'Cash',
    surgery: 'Surgery',
    nonSurgical: 'Non Surgical',
    followUp: 'Follow Up',
    consultation: 'Consultation',
    toSeller: 'To Seller',
    other: 'Other',
    earningsReport: 'Earnings Report',
    service: 'Service',
    services: 'Services',
    shoppingCart: 'Shopping Cart',
    remove: 'Remove',
    total: 'Total',
    qty: 'Qty',
    noItemsSelected: 'No Items Selected',
    paymentNumber: 'Payment Number',
    paymentReceipt: 'Payment Receipt',
    shippingTaxesCalculatedCheckout: 'Shipping and taxes calculated at checkout.',
    goBack: 'Go Back',
    checkout: 'Checkout',
    or: 'or',
    totalQuotes: 'Total Quotes',
    availableBalance: 'Available Balance',
    pendientBalance: 'Pendent Balance',
    deposit: 'Deposit',
    close: 'Close',
    cancelSaleCauses: 'Cancel Sale Causes',
    value: 'Value',
    saleCreatedSuccessfully: 'Sale created successfully',
    sell: 'Sell',
    fee: 'Fee',
    dispatch: 'Dispatch',
    showingContactInfo: 'Showing Contact Info Here',
    createNewMassageAppointment: 'Create New Massage Appointment',
    errorCheckInAppointment: 'Error Check in Appointment',
    appointmentType: 'Appointment Type',
    totalBalance: 'Total Balance',
    checkIn: 'Check In',
    clientName: 'Client Name',
    clientId: 'Client Id',
    checked: 'Checked',
    providers: 'Providers',
    addAppointment: 'Add Appointment',
    cancelSaleCreditBalance: 'The amount of ${field} will be added to your credit balance',
    sale: 'Sale',
    addDepositTo: 'Add Deposit to',
    editDepositTo: 'Edit Deposit to',
    unauthorizedAppointmentsList: "You don't have authorization to view appointments list",
    errorNotPendentBalance: "The patient doesn't have a pendent balance",
    cardNumber: 'Card Number',
    cardHolder: 'Card Holder',
    Nursing: 'Nursing',
    NonNursing: 'Non Nursing',
    massage: 'Massage',
    selectService: 'Select Service',
    selectProvider: 'Select Provider',
    selectProcedure: 'Select Procedure',
    formIncomplete: 'You must complete the form',
    appointmentServiceDeletedSuccessfully: 'Appointment service deleted successfully',
    confirmDeleteAppointmentService: 'Confirm Deletion of Appointment Service',
    deleteAppointmentService: 'Delete Appointment Service',
    types: 'Types',
    causes: 'Causes',
    massageAppointment: 'Massage Appointment',
    nonSurgicalAppointment: 'Non Surgical Appointment',
    evaluationAppointment: 'Evaluation Appointment',
    cosmetologyAppointment: 'Cosmetology Appointment',
    coordinator: 'Coordinator',
    youWantToCancelCheckIn: 'Are you sure you want to cancel the checkin of this appointment?',
    appointmentCancelCheckIn: 'The appointment will be cancel the checkin',
    cancelCheckIn: 'Cancel checkin',
    notPayFee: "Don't pay fee",
    payFeeLater: 'Pay the fee later on',
    youWantToSendSmsToPatient:
        'Are you sure you want send an SMS to the client? This may come with irreversible actions.',
    notifyPatient: 'Notify Patient',
    charger: 'Charger',
    pay: 'Pay',
    NonSurgical: 'No Surgical',
    itemRemovedSuccessfully: 'Treatment item removed Successfully',
    confirmDeleteTreatmentItem: 'Confirm Deletion of treatment item',
    authorizeTreatmentRefund: 'Authorize treatment refund',
    updateTreatment: 'Update treatment',
    createTreatment: 'Create treatment',
    itemPaidSuccessfully: 'Item paid successfully',
    last4: 'Last 4',
    activeQuotes: 'Active Quote',
    items: 'Items',
    changeDepartment: 'Change department',
    changePaymentMethod: 'Change Payment Method',
    transferToTreatment: 'Transfer to {item} from Treatment #{quote}',
    changeSeller: 'Change seller',
};

export default messages;
