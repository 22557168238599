﻿import { RouterView } from 'vue-router';
const AllRefunds = () => import('@/pages/administration/AllRefunds.vue');
const AllAppointments = () => import('@/pages/frontdesk/AllAppointments.vue');
const CheckOutSurgeryAppointment = () =>
    import('@/pages/medicalServices/surgeries/checkOut/CheckOutSurgeryAppointment.vue');

const adminRoutes = [
    {
        path: 'admin',
        name: 'Administration',
        redirect: (to) => {
            return { path: '/admin/procedures' };
        },
        component: RouterView,
        meta: {
            title: 'Administration',
            public: false,
            icon: 'CogIcon',
        },
        children: [
            {
                path: 'appointments',
                name: 'AdminAppointments',
                component: AllAppointments,
                meta: {
                    menu: true,
                    title: 'Appointments',
                    permissionsAnyOf: [
                        'Permissions.SuperAdmin.Appointments.RollbackCompletedCosmetology',
                        'Permissions.SuperAdmin.Appointments.RollbackCompletedMassage',
                        'Permissions.SuperAdmin.Appointments.ChangeCheckOut',
                    ],
                },
                props: {
                    checkOutRoute: 'AdminChangeCheckOutSurgeryAppointment',
                },
            },
            {
                path: 'procedures',
                name: 'AdminRefunds',
                component: AllRefunds,
                meta: {
                    menu: true,
                    title: 'Refunds',
                    permissionsAnyOf: ['Permissions.SuperAdmin.Refunds.Update'],
                },
            },
            {
                path: 'appointments/change-check-out/:id/:patientId',
                name: 'AdminChangeCheckOutSurgeryAppointment',
                component: CheckOutSurgeryAppointment,
                meta: {
                    public: false,
                    title: 'CheckOut',
                    header: true,
                },
                props: {
                    editable: true,
                    route: 'AllAppointments',
                },
            },
        ],
    },
];

export default adminRoutes;
