const PaymentReceipt = () => import('@/pages/invoice/PaymentReceipt.vue');
const RefundReceipt = () => import('@/pages/invoice/RefundReceipt.vue');
const TpvSaleReceipt = () => import('@/pages/invoice/frontDesk/TpvSaleReceipt.vue');
const AccountingInvoice = () => import('@/pages/invoice/accounting/AccountingInvoice.vue');
const PendingInvoice = () => import('@/pages/invoice/PendingInvoice.vue');
const QuotePaymentInvoice = () => import('@/pages/invoice/QuotePaymentInvoice.vue');
const QuotePaymentReceipt = () => import('@/pages/invoice/QuotePaymentReceipt.vue');

const invoiceRoutes = [
    {
        path: '/invoice/:id',
        name: 'AccountingInvoice',
        component: AccountingInvoice,
        meta: {
            title: 'AccountingInvoice',
            public: true,
        },
    },
    {
        path: '/tpv-sale/:id',
        name: 'TpvSaleReceipt',
        component: TpvSaleReceipt,
        meta: {
            title: 'TpvSaleReceipt',
            public: true,
        },
    },
    {
        path: '/payment-receipt/:id',
        name: 'PaymentReceipt',
        component: PaymentReceipt,
        meta: {
            title: 'PaymentReceipt',
            public: true,
        },
    },
    {
        path: '/refund-receipt/:id/:item?',
        name: 'RefundReceipt',
        component: RefundReceipt,
        meta: {
            title: 'RefundReceipt',
            public: true,
        },
    },
    {
        path: '/pending-invoice/:id',
        name: 'PendingInvoice',
        component: PendingInvoice,
        meta: {
            title: 'PendingInvoice',
            permissionsAnyOf: ['Permissions.FrontDesk.PendingInvoice.Detail'],
        },
    },
    {
        path: '/quote-payments/:id',
        name: 'QuotePaymentInvoice',
        component: QuotePaymentInvoice,
        meta: {
            title: 'QuotePaymentInvoice',
            permissionsAllOf: [
                'Permissions.Sales.QuoteTransfers.Detail',
                'Permissions.Sales.Quotes.Detail',
                'Permissions.Setting.PaymentMethod.Search',
            ],
            permissionsAnyOf: [
                'Permissions.FrontDesk.PublicityLeads.Detail',
                'Permissions.Publicity.Leads.Detail',
                'Permissions.Sales.LeadProfiles.Detail',
            ],
        },
    },
    {
        path: '/quote-receipts/:id',
        name: 'QuotePaymentReceipt',
        component: QuotePaymentReceipt,
        meta: {
            title: 'QuotePaymentReceipt',
            permissionsAllOf: [
                'Permissions.Sales.QuoteTransfers.Detail',
                'Permissions.Sales.Quotes.Detail',
                'Permissions.Setting.PaymentMethod.Search',
            ],
            permissionsAnyOf: [
                'Permissions.FrontDesk.PublicityLeads.Detail',
                'Permissions.Publicity.Leads.Detail',
                'Permissions.Sales.LeadProfiles.Detail',
            ],
        },
    },
];

export default invoiceRoutes;
