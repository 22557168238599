const messages = {
    phone2FA: '2FA Phone',
    phoneNotification: 'Notification Phone',
    changePassword: 'Change Password',
    currentPassword: 'Current Password',
    newPassword: 'New Password',
    confirmPassword: 'Confirm Password',
    submit: 'Submit',
    myProfile: 'My Profile',
    passwordChangeSuccessfully: 'The password was change successfully',
    Password: 'Password',
    forgotPassword: 'Forgot Password',
    checkYourEmail: 'Please check your email.',
    enterEmailAssociatedYourAccount: 'Please enter the email associated with your account.',
    send: 'Send',
    enterYourNewPassword: 'Please enter your new password.',
    resetPassword: 'Reset Password',
    verifyYourIdentity: 'Verify Your Identity',
    receiveVerificationCode: 'Receive Verification Code by',
    enterCodeSentToYour: 'Please enter the code sent to your',
    verify: 'Verify',
    resend: 'Resend',
    editPermissions: 'Permissions edited successfully',
    workingSince: 'Company Employee Since',
    confirmUnlockAccount: 'Confirm User Account Unblock',
    confirmLoginAccount: 'Confirm Login as user {field} account',
    noticeUnlock: 'This action allows the user to access the system, some data could be lost',
    noticeImpersonateLogin:
        'This action allows the user to access the system, some data could be lost',
    userAccountUnlokedSuccessfully: 'User account has been unloked successfully',
    login: 'Login',
    greeting: 'Hello',
    updatePasswordDescription:
        'We requires you to update your personal password, please, update it now',
    resetPasswordRequest: 'Reset Password',
    confirmRequestUpdatePassword: 'Confirm Update Password Request',
    requestUpdatePasswordSuccessfully: 'The password update request was created successfully.',
    noticeRequestUpdatePassword:
        'This action will prompt the user upon login to change their password',
    requestPasswordUpdate: 'Password change required',
    requestPasswordUpdateDescription: 'User must change their password at the next login',
};

export default messages;
