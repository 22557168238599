import { createRouter, createWebHistory } from 'vue-router';
import { routes } from './routes';
import { useIdentityStore } from '@/store/identity.store';
import { ProgressFinisher, useProgress } from '@marcoschulte/vue3-progress';
import { usePublicityStore } from '@/store/publicity.store';
//import { Controller } from '@/services/HttpClient';

const progresses = [] as ProgressFinisher[];

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
});

router.beforeEach(async (to, from) => {
    //if (to.fullPath != from.fullPath) {
    //    Controller.value.abort();
    //    Controller.value = new AbortController();
    //}
    progresses.push(useProgress().start());

    const publicityStore = usePublicityStore();
    if (publicityStore.isShowcaseModeOn && to.name !== 'ShowcaseTv') {
        console.debug('Locked in', to, from);
        return {
            name: 'ShowcaseTv',
            replace: true,
            params: {
                id: publicityStore.showcaseParams.deviceId,
                date: publicityStore.showcaseParams.date,
            },
        };
    }

    const identityStore = useIdentityStore();
    if (!to.meta.public && identityStore.refreshTokenExpired()) {
        progresses.pop()?.finish();
        identityStore.setReturnUrl(to.path);
        await identityStore.logout();
        return '/login';
    } else if (to.name == 'Login' && identityStore.data && !identityStore.refreshTokenExpired()) {
        progresses.pop()?.finish();
        return '/';
    } else if (
        !identityStore.authorized(
            to.meta.permissionsAllOf as string[] | undefined,
            to.meta.permissionsAnyOf as string[] | undefined
        )
    ) {
        progresses.pop()?.finish();
        return { name: 'Forbidden', replace: true };
    }
});

router.afterEach(() => {
    progresses.pop()?.finish();
});

export default router;
