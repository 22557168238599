﻿import dayjs from 'dayjs';
import { Notifications } from '@/composables/notification';
import { ProviderSchedulesApi } from '@/openapi/provider-schedules-api';
import { ref } from 'vue';
import { ProviderBlockScheduleDto } from '@/models/provider-block-schedule-dto';

export const blockSchedules = ref<Array<ProviderBlockScheduleDto>>([]);
export const blockMessage = ref<string>();
export const getBlockSchedule = async () => {
    const apiSchedules = new ProviderSchedulesApi();
    apiSchedules
        .providerSchedulesGetBlockSchedules()
        .then(async (response) => {
            if (response.data.success) {
                blockSchedules.value = response.data.data;
            }
        })
        .catch((e) => {
            Notifications.Error(String(e));
        });
};
export const validateBlockDates = (date, provider, t) => {
    let valid = true;
    blockSchedules?.value.forEach((e) => {
        if (e.id === provider && !!date) {
            e.blockSchedules.forEach((b) => {
                if (dayjs(b.startDate) < dayjs(date) && dayjs(date) < dayjs(b.endDate)) {
                    blockMessage.value = t('providerBlock', {
                        start: dayjs(b.startDate).format('MM/DD/YYYY'),
                        end: dayjs(b.endDate).format('MM/DD/YYYY'),
                    });
                    valid = false;
                }
            });
        }
    });
    return valid;
};

export const checkBlockSchedule = (val, date, t) =>
    validateBlockDates(date, val, t) ? true : blockMessage.value;
